
import React from 'react'
import Index from '../../templates/index'

const IndexEsPage = () => {
    return (
        <Index lang="es">
        </Index>

    )
}
export default IndexEsPage
